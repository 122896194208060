<template>
    <b-modal id="onboarding-single-purchase" hide-footer hide-header centered>
        <div>
            <h6 class="font-weight-bold text-center">Success! Your Purchase is Complete.</h6>
            <p class="text-center mt-2 mb-3 f-12">You can now publish, download, or print your worksheet. Hooray!</p>

            <div class="message-box text-center p-3">
                <p>SPECIAL BONUS: Full Features Unlocked!</p>
                <svg width="89" height="85" viewBox="0 0 89 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M86 42.5C86 64.1867 67.5513 82 44.5 82C21.4487 82 3 64.1867 3 42.5C3 20.8133 21.4487 3 44.5 3C67.5513 3 86 20.8133 86 42.5Z"
                        fill="#FFE976"
                        stroke="#EA289C"
                        stroke-width="6"
                    />
                    <path
                        d="M47.9468 38.5934H66.1201V64C66.1201 65.6568 64.777 67 63.1201 67H47.9468V38.5934Z"
                        fill="#EA289C"
                    />
                    <path
                        d="M22.8799 38.5934H41.0532V67H25.8799C24.223 67 22.8799 65.6568 22.8799 64V38.5934Z"
                        fill="#EA289C"
                    />
                    <path
                        d="M21 28.2968C21 26.6399 22.3431 25.2968 24 25.2968H65C66.6569 25.2968 68 26.6399 68 28.2968V35.5715H21V28.2968Z"
                        fill="#EA289C"
                    />
                    <path
                        d="M41.9 22.7555C40.8538 19.9899 37.0779 17.1301 32.5026 15.9005C31.1646 15.5409 29.7894 15.35 28.8625 15.9312C27.8323 16.5772 26.718 18.0757 27.6565 19.1143C29.0093 20.6112 31.8036 22.1696 34.0476 23.1347C35.7975 23.8874 38.3151 23.9848 39.9188 24.7688"
                        stroke="#EA289C"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M46.7626 25.2363C49.8005 25.5228 54.1073 23.4765 57.2359 20.0298C58.1507 19.0219 58.9241 17.9087 58.7817 16.8512C58.6234 15.676 57.7046 14.0588 56.3277 14.4257C54.3432 14.9545 51.6776 16.7102 49.8048 18.2441C48.3442 19.4402 47.1641 21.5872 45.7376 22.6427"
                        stroke="#EA289C"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                    <ellipse cx="44.8131" cy="25.9011" rx="5.01333" ry="4.83517" fill="#EA289C" />
                </svg>
                <div class="mt-2 f-13">
                    <p>
                        To say thank you for your purchase, we've granted you full,
                        <strong>free access to all features and sister sites</strong>
                        for
                        <strong>30 days.</strong>
                    </p>

                    <p>
                        Create worksheets, crosswords, word searches, bringo, and more! Explore every activity maker and
                        publish unlimited documents.
                    </p>

                    <p>After 30 days, your account will revert to free features only.</p>

                    <p>
                        Discover
                        <a
                            href="https://myworksheetmaker.groovehq.com/help/what-are-your-other-sites-do-you-have-other-builders-for-teachers"
                            class="text-danger underline"
                            target="_blank"
                            rel="nofollow"
                        >
                            everything you can do.
                        </a>
                    </p>

                    <strong>Happy Creating! We can't wait to see what you make.</strong>
                    😍️
                </div>
            </div>
            <div class="d-flex mt-2 justify-content-center">
                <button
                    class="btn btn-primary btn-sm mr-2"
                    @click="
                        $bvModal.hide('onboarding-single-purchase')
                        $bvModal.show('printable-modal')
                    "
                >
                    Publish Worksheet
                </button>
                <button class="btn btn-secondary btn-sm" @click="$bvModal.hide('onboarding-single-purchase')">
                    Back to Builder
                </button>
            </div>
            <p class="mb-0 mt-2 f-12 text-center">🍎 Thank you for your support of educators around the world! 🍎</p>
        </div>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
    name: 'OnboardingSinglePurchase',
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            worksheet: 'document/worksheet',
            isWorksheet: 'document/isWorksheet',
            hasPlans: 'subscription/hasPlans',
        }),
        ...mapState(['user', 'document', 'subscription']),
    },
})
</script>

<style scoped>
.message-box {
    background: rgba(113, 93, 214, 0.04);
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;
}
.underline {
    text-decoration: underline;
}
</style>
